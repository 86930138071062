import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`An ECDN server’s health status.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> ServerHealth <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of services affecting content delivery.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">contentDelivery</GQLName>: <GQLLink href="/ecdn-api-objects/ContentDeliveryHealth" mdxType="GQLLink">ContentDeliveryHealth</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of operational services (ones that doesn't affect content delivery).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">operations</GQLName>: <GQLLink href="/ecdn-api-objects/OperationsHealth" mdxType="GQLLink">OperationsHealth</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`contentDelivery: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/ContentDeliveryHealth"
      }}>{`ContentDeliveryHealth`}</a>{`!`}</h3>
    <p>{`Health of services affecting content delivery.`}</p>
    <h3>{`operations: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/OperationsHealth"
      }}>{`OperationsHealth`}</a>{`!`}</h3>
    <p>{`Health of operational services (ones that doesn’t affect content delivery).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      